// firebase.js---
import firebase from 'firebase/app';
import 'firebase/auth';

// firebase config
// local 用s2的設定
const firebaseConfig = {
    apiKey: "AIzaSyDNPBeSXPpxIlrLOxiTZVNU69OKLdSHsfA",
    authDomain: "prod-premium.firebaseapp.com",
    projectId: "prod-premium",
    storageBucket: "prod-premium.appspot.com",
    messagingSenderId: "1006595943909",
    appId: "1:1006595943909:web:b62032e9064734eacd930c",
    measurementId: "G-ZX62024F7G"
};

export default firebase.initializeApp(firebaseConfig);
// firebase.analytics();
