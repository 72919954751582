import $_ from 'lodash'
import httpRequest from '@/global/http.js'
// utils
import articleUtil from '@/assets/js/utils/article'
import util from '@/global/util.js'

const moduleArticle = {
    namespaced: true,
    state: {
        articleUtil: articleUtil,  // 文章常用函數

        // 文章資料相關變數
        articleData: {},  // API取得的文章資料
        articleBasicInfo: {},  // 文章基本資訊
        isPreview: false,  // 是否為編輯者正在預覽文章
        fromCategory: {},  // 頁面導向來源的課程分類資訊

        // 體驗試閱文章相關變數
        nextTargetPath: '',  // 離開 article.vue 主路由後, 下一個欲到達的頁面

        // 單字查詢相關變數
        activeSearchedWord: { id: null },  // 正在查詢的單字
        dictionaryResult: {},  // 字典釋義結果
        isShowDictBriefResult: false,  // 是否顯示字典簡略釋義小框
        isShowDictResultDialogue: false,  // 是否開啟字典釋義彈窗
        articleContentHeight: 0,  // 文章內容高度(以用來判斷簡略釋義小框顯示在單字上方 or 下方)

        // 收藏單字相關變數
        userMarkedWords: [],  // 使用者在此篇文章下收藏的單字們
        isMarkWordSuccess: false,  // 是否成功收藏單字
        allMarkedWordIds: [],  // 使用者在此篇文章下收藏的單字 ids
        tempWordId: 0,  // 目前收藏單字 id (未登入試閱文章用)
        tempWordInfoList: {},  // 暫存收藏單字的釋義列表 (未登入試閱文章用)
        tempSortedWordList: [],  // 暫存目前收藏單字字首 A-Z 排序後的列表 (未登入試閱文章用)

        // 選擇單字程度相關變數
        wordLevels: [],  // 單字程度列表
        selectedWordLevel: {  // 使用者選擇的單字程度物件 (預設不顯示)
            id: '0',
            name: '不顯示'
        },
        articleLevelWords: [],  // 取得該文章下符合選擇單字程度的單字們

        // 筆記相關變數
        articleNotes: {},  // 文章筆記內容
        isOpenNote: false,  // 是否開啟筆記
        isScrollRightArticleContent: false,  // 是否右滑文章內容區塊(適用關閉筆記時)
        isShowNotSavePrompt: false,  // 離開頁面前是否顯示系統不自動儲存提示

        // 文章聽讀與講解播放清單相關變數
        listeningSentencesPlayList: [],  // 文章段落詞句跟讀播放清單
        teachingCommentsPlayList: [],  // 文章標題, 段落詞句與補充區塊有標時間點的講解內容播放清單
        playbackRates: [1, 0.75, 1.25],  // 可選擇的播放速度

        // 題目練習相關變數
        articlePracticeInfo: null,  // 文章練習分類資訊(包含練習是否顯示分數)
        isPracticing: false,  // 是否正在練習中
        isShowNotSavePracticeHistoryPrompt: false,  // 離開正在進行的練習, 是否顯示系統不自動儲存提示
        // v1
        practiceQuestionInfo: null,  // 練習題目資訊 [待移除]
        // v2
        practicingCategory: { id: '', scoreType: '' },  // 正在練習的分類資訊
    },
    getters: {
        isShowCourseInfoBar: (state, getters, rootState) => {  // 是否顯示課程資訊 bar
            // 1. 文章資料尚未取得
            // 2. 後臺預覽文章狀態
            if ($_.isNull(state.articleData) || $_.isEmpty(rootState.course.courseBasicInfo) || state.isPreview) {
                return false;
            }

            // 符合以下所有條件時, 才顯示課程資訊內容
            // 1. 試閱文章
            // 2. 非預購型課程(一般課程)
            // 3. 使用者未登入 or 未購買課程(非 admin)
            return state.articleData.is_public && !rootState.course.courseBasicInfo.courseInfo.isPreOrder && ($_.isEmpty(rootState.common.userInfo) || (!$_.isEmpty(rootState.common.userInfo) && rootState.common.userInfo.permission !== 'admin' && !rootState.course.courseBasicInfo.isCourseUser));
        },
        scrollToTargetOffset: (state, getters) => {  // 卷軸移動到目標位置的 offset
            return getters.isShowCourseInfoBar ? 180 : 100;
        },
        isShowLoginPrompt: (state, getters, rootState) => {  // 是否顯示登入提示
            // 1. 未登入使用者
            // 2. 筆記有內容 或 有收藏單字
            return !!(
                $_.isEmpty(rootState.common.userInfo) &&
                ((!state.isOpenNote && !$_.isEmpty(state.articleNotes) && state.articleNotes.content) ||
                state.userMarkedWords.length)
                );
        }
    },
    mutations: {
        // 文章資料相關
        setArticleData(state, payload) {
            state.articleData = payload;
        },
        setArticleBasicInfo(state, payload) {
            state.articleBasicInfo = payload;
        },
        setFromCategory(state, payload) {
            state.fromCategory = payload;
        },

        // 體驗試閱文章相關
        setNextTargetPath(state, payload) {
            state.nextTargetPath = payload;
        },

        // 單字查詢相關
        setActiveSearchedWord(state, payload) {
            state.activeSearchedWord = payload;
        },
        setDictionaryResult(state, payload) {
            state.dictionaryResult = payload;
        },
        setIsShowDictBriefResult(state, payload) {
            state.isShowDictBriefResult = payload;
        },
        setIsShowDictResultDialogue(state, payload) {
            state.isShowDictResultDialogue = payload;
        },
        setArticleContentHeight(state, payload) {
            state.articleContentHeight = payload;
        },

        // 收藏單字相關
        setUserMarkedWords(state, payload) {
            state.userMarkedWords = payload;
        },
        setIsMarkWordSuccess(state, payload) {
            state.isMarkWordSuccess = payload;
        },
        setAllMarkedWordIds(state, payload) {
            state.allMarkedWordIds = payload;
        },
        setTempWordId(state, payload) {
            state.tempWordId = payload;
        },
        setTempWordInfoList(state, payload) {
            state.tempWordInfoList = payload;
        },
        setTempSortedWordList(state, payload) {
            state.tempSortedWordList = payload;
        },

        // 選擇單字程度相關
        setWordLevels(state, payload) {
            state.wordLevels = payload;
        },
        setSelectedWordLevel(state, payload) {
            state.selectedWordLevel = payload;
        },
        setArticleLevelWords(state, payload) {
            state.articleLevelWords = payload;
        },

        // 筆記相關
        setArticleNotes(state, payload) {
            state.articleNotes = payload;
        },
        setIsOpenNote(state, payload) {
            state.isOpenNote = payload;
        },
        setIsScrollRightArticleContent(state, payload) {
            state.isScrollRightArticleContent = payload;
        },
        setIsShowNotSavePrompt(state, payload) {
            state.isShowNotSavePrompt = payload;
        },

        // 文章聽讀與講解播放清單相關
        setListeningSentencesPlayList(state, payload) {
            state.listeningSentencesPlayList = payload;
        },
        setTeachingCommentsPlayList(state, payload) {
            state.teachingCommentsPlayList = payload;
        },

        // 題目練習相關
        setArticlePracticeInfo(state, payload) {
            state.articlePracticeInfo = payload;
        },
        setIsPracticing(state, payload) {
            state.isPracticing = payload;
        },
        setIsShowNotSavePracticeHistoryPrompt(state, payload) {
            state.isShowNotSavePracticeHistoryPrompt = payload;
        },
        // v1
        setPracticeQuestionInfo(state, payload) {
            state.practiceQuestionInfo = payload;
        },
        // v2
        setPracticingCategory(state, payload) {
            state.practicingCategory = payload;
        },
    },
    actions: {
        getArticleData({ state, commit }, payload) {
            if (!payload.articleId || !typeof payload.isPreview === 'boolean') {
                return;
            }

            state.isPreview = payload.isPreview;  // 文章預覽

            return new Promise((resolve, reject) => {
                let params = {
                    articleId: payload.articleId
                }

                if (state.isPreview) {
                    params.isPreview = '1';
                }

                httpRequest.get('/api/article/get_article_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result && !$_.isEmpty(result)) {
                                commit('setArticleData', result);

                                // 文章基本資訊
                                let basicInfoData = {
                                    id: payload.articleId,
                                    type: result.type,  // 0: 英文  1: 非語文
                                    name: result.name,
                                    subName: result.sub_name,
                                    issueNoDate: result.issue_no_date ? util.unixTimestampToDatetime(result.issue_no_date, util.getBrowserCurrentTimeZone(), 'date') : '',
                                    difficultyLevel: result.difficulty_level,
                                    category: result.category.name,
                                    categoryId: result.category.id,
                                    tags: [],  // 副分類
                                    cover: {
                                        path: !$_.isNull(result.cover) ? result.cover.file_path : '',
                                        description: !$_.isNull(result.cover) ? result.cover.description : ''
                                    },
                                    // 判斷文章是否有講解影音檔案以顯示 sidebar '內容講解'
                                    isTeachShow: !$_.isNull(result.media),
                                    // 判斷文章是否有自建練習題分類以顯示 sidebar '題目練習'
                                    isPracticeShow: result.is_has_practice_category,
                                    // 判斷文章是否有加入某個書籤
                                    isBookmark: result.isBookmark
                                }
                                // 副分類
                                for (let el of result.tags) {
                                    basicInfoData.tags.push(el.name);
                                }
                                commit('setArticleBasicInfo', basicInfoData);

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getArticleIdByAction(_, payload) {
            if (!payload.articleId || !payload.courseId || !['-1', '1'].includes(payload.action)) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    articleId: payload.articleId,
                    courseId: payload.courseId,
                    action: payload.action
                }

                httpRequest.get('/api/article/get_article_id_by_action', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                resolve(result);
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getUserMarkedWords({ commit }, payload) {
            if (!payload.articleId) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    articleId: payload.articleId
                }

                httpRequest.get('/api/article/get_article_marked_words', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                commit('setUserMarkedWords', result);

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getWordLevels({ state, commit }) {
            if (state.wordLevels.length) {
                return;
            }

            return new Promise((resolve, reject) => {
                httpRequest.get('/api/article/get_level_type_list')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let levels = [
                                    {
                                        id: "0",
                                        name: "不顯示"
                                    }
                                ]
                                levels = $_.concat(levels, result);
                                commit('setWordLevels', levels);

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        searchWord({ state, dispatch }) {
            return new Promise((resolve, reject) => {
                let params = {
                    articleId: state.articleBasicInfo.id,
                    word: articleUtil.handleWord(state.activeSearchedWord.word, true)
                }

                state.dictionaryResult = {};

                httpRequest.get('/api/article/search_words', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                state.dictionaryResult = {
                                    word: params.word,
                                    lexicalEntries: []
                                }

                                for (let el of result) {
                                    if ($_.has(el, 'lexicalEntries')) {
                                        for (let entry of el.lexicalEntries) {
                                            // 詞性分類
                                            let lexicalCategory = "";
                                            lexicalCategory = articleUtil.setLexicalCategoryAbbrev(entry.lexicalCategory.text);

                                            let currentEntry = entry.entries[0];  // 目前詞性內容

                                            // 發音音檔, KK音標
                                            let pronunciationAudioFile = "", phoneticSpelling = "";
                                            if ($_.has(currentEntry, 'pronunciations')) {
                                                // 取到第一個美式發音音檔後即結束
                                                currentEntry.pronunciations.some(pronunciation => {
                                                    if ($_.has(pronunciation, 'dialects') &&
                                                        pronunciation.dialects[0] &&
                                                        pronunciation.dialects[0] == 'American English') {
                                                        pronunciationAudioFile = pronunciation.audioFile;
                                                        phoneticSpelling = pronunciation.phoneticSpelling;
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                });
                                            }

                                            // 中文釋義, 英文例句與中文解釋
                                            let translations = [], examples = [];
                                            if ($_.has(currentEntry, 'senses')) {
                                                for (let sense of currentEntry.senses) {
                                                    if ($_.has(sense, 'translations') &&
                                                        sense.translations[0] &&
                                                        $_.has(sense.translations[0], 'text')) {
                                                        translations.push(sense.translations[0].text);
                                                    }

                                                    if ($_.has(sense, 'examples')) {
                                                        for (let example of sense.examples) {
                                                            let tempTranslations = [];
                                                            for (let translation of example.translations) {
                                                                tempTranslations.push(translation.text);
                                                            }

                                                            examples.push({
                                                                text: example.text,
                                                                translations: tempTranslations
                                                            });
                                                        }
                                                    }
                                                }
                                            }

                                            // 單字發音音檔, 發音音標與中文釋義三者皆有才放進結果
                                            if ($_.has(state.dictionaryResult, 'lexicalEntries') &&
                                                pronunciationAudioFile &&
                                                phoneticSpelling &&
                                                translations.length) {
                                                state.dictionaryResult.lexicalEntries.push({
                                                    lexicalCategory: lexicalCategory,
                                                    pronunciationAudioFile: pronunciationAudioFile,
                                                    phoneticSpelling: phoneticSpelling,
                                                    translations: translations,
                                                    examples: examples,
                                                    isPlayingSound: false  // 是否正在播放發音音檔
                                                });
                                            }
                                        }
                                    }
                                }

                                if ($_.has(state.dictionaryResult, 'lexicalEntries') &&
                                    !state.dictionaryResult.lexicalEntries.length) {
                                    state.dictionaryResult = {};
                                    dispatch('common/setAlert', { status: 'danger', msg: '暫無解釋' }, { root: true });
                                }

                                resolve();
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            // 暫無解釋
                            resolve();
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        async updateMarkedWord({ state, dispatch, rootState }, payload) {
            let handledWord = articleUtil.handleWord(state.activeSearchedWord.word, true);
            let toastMsg = '';

            // 已登入使用者, 更新收藏單字至 server
            if (!$_.isEmpty(rootState.common.userInfo)) {
                toastMsg = await dispatch('updateMarkedWordToServer', payload);
            }
            // 未登入使用者, 暫存收藏單字在瀏覽器記憶體中
            else {
                if (!state.activeSearchedWord.isMarked) {
                    toastMsg = '單字收藏成功';

                    state.tempWordId++;
                    state.allMarkedWordIds.push(state.tempWordId);
                    state.tempWordInfoList[state.tempWordId] = {
                        id: state.tempWordId,
                        word: handledWord,
                        isImportant: false,
                        isMarked: true,
                        dictionary: {
                            lexicalCategory: state.dictionaryResult.lexicalEntries[0].lexicalCategory,
                            translation: state.dictionaryResult.lexicalEntries[0].translations[0]
                        }
                    }
                    // 排序用
                    state.tempSortedWordList.push({
                        id: state.tempWordId,
                        word: handledWord,
                        isImportant: false
                    });
                } else {
                    toastMsg = '單字取消收藏成功';

                    let removedId = $_.findKey(state.tempWordInfoList, el => {
                        return el.word === handledWord;
                    });
                    removedId = +removedId;

                    state.allMarkedWordIds = $_.remove(state.allMarkedWordIds, el => {
                        return el !== removedId;
                    });
                    state.tempSortedWordList = $_.remove(state.tempSortedWordList, el => {
                        return el.id !== removedId;
                    });
                    state.tempWordInfoList = $_.omit(state.tempWordInfoList, [removedId]);
                }
            }

            state.activeSearchedWord.isMarked = !state.activeSearchedWord.isMarked;

            dispatch('common/setAlert', { status: 'success', msg: toastMsg }, { root: true });

            // 收藏單字需將單字 push 至 userMarkedWords ; 取消收藏單字需將單字從 userMarkedWords 中移除
            if (state.activeSearchedWord.isMarked) {
                state.userMarkedWords.push(handledWord);
            } else {
                state.userMarkedWords = $_.remove(state.userMarkedWords, el => {
                    return el !== handledWord;
                });
            }

            // 收藏單字成功, 放大 sidebar '單字收藏' icon
            state.isMarkWordSuccess = state.activeSearchedWord.isMarked;
        },
        updateMarkedWordToServer({ state }, payload) {
            if (!payload.articleId) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    articleId: payload.articleId,
                    word: articleUtil.handleWord(state.activeSearchedWord.word, true),
                    isMarked: !state.activeSearchedWord.isMarked
                }

                httpRequest.post('/api/article/update_article_marked_word', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            resolve(response.data.msg);
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        async updateWordIsImportant({ state, dispatch, rootState }, payload) {
            let toastMsg = '';

            // 已登入使用者, 更新單字標記結果至 server
            if (!$_.isEmpty(rootState.common.userInfo)) {
                toastMsg = await dispatch('updateImportantWordToServer', payload);
            }
            // 未登入使用者, 暫存單字標記結果在瀏覽器記憶體中
            else {
                toastMsg = !payload.wordItem.isImportant ? '單字標記成功' : '單字取消標記成功';

                for (let el of state.tempSortedWordList) {
                    if (el.word === payload.wordItem.word) {
                        el.isImportant = !payload.wordItem.isImportant;
                    }
                }
            }

            payload.wordItem.isImportant = !payload.wordItem.isImportant;

            dispatch('common/setAlert', { status: 'success', msg: toastMsg }, { root: true });
        },
        updateImportantWordToServer(_, payload) {
            if (!payload.wordItem) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    word: articleUtil.handleWord(payload.wordItem.word, true),
                    isImportant: !payload.wordItem.isImportant
                }

                httpRequest.post('/api/article/update_marked_word_important', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            resolve(response.data.msg);
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        updateNoteToServer({ state }, payload) {
            if (!payload.articleId) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    articleId: payload.articleId,
                    content: state.articleNotes.content
                }

                httpRequest.post('/api/article/update_article_note', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            resolve(response.data.msg);
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        async updateNoteAndWords({ state, dispatch }, payload) {
            if (!payload.articleId) {
                return;
            }

            // 更新筆記內容
            if (!$_.isEmpty(state.articleNotes) && state.articleNotes.content) {
                await dispatch('updateNoteToServer', payload);
            }

            // 更新收藏單字
            if (state.tempSortedWordList.length) {
                await dispatch('addMarkedWordsToServer', payload);
            }
        },
        addMarkedWordsToServer({ state }, payload) {
            if (!payload.articleId) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    articleId: payload.articleId,
                    words: state.tempSortedWordList
                }

                httpRequest.post('/api/article/add_article_marked_words', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            resolve(response.data.msg);
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getArticlePracticeInfo({ state }, payload) {
            if (!payload.articleId) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    articleId: payload.articleId
                }

                httpRequest.get('/api/practice/get_practice_category_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                // API傳回的資料中, 文章內沒有練習分類則直接 reject()
                                if (!result.categories.length) {
                                    reject("Get Info Error: No category");
                                }

                                state.articlePracticeInfo = {
                                    isShowScore: result.is_show_score,  // false: 顯示"答對題數" ; true: 顯示"得分分數"
                                    categories: []
                                }

                                // 練習分類
                                for (let el of result.categories) {
                                    state.articlePracticeInfo.categories.push({
                                        id: el.pc_id,
                                        name: el.name,
                                        info: el.sub_name,
                                        scoreType: el.score_type,
                                        lastTestedId: el.last_tested_id,  // 若沒有做過此分類的練習, 則值為"0"
                                        testingTestedId: el.testing_tested_id,  // 暫存中的 testedId
                                        isEnable: el.pick_num > 0,  // 是否開放測驗(抽題量 > 0)
                                        isReachedTimes: !el.is_enable,  // 是否已到達練習次數上限
                                        isGettingQuestionInfo: false,  // 是否正在取得練習題目資訊
                                    });
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getPracticeQuestionInfo({ state }, payload) {
            if (!payload.articleId || !payload.categoryId || !['0', '1', '2'].includes(payload.categoryScoreType)) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    articleId: payload.articleId,
                    practiceCategoryId: payload.categoryId
                }

                if (state.isPreview) {
                    params.isPreview = '1';
                }

                httpRequest.get('/api/practice/get_practice_question', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                state.practiceQuestionInfo = {
                                    categoryId: payload.categoryId,
                                    categoryScoreType: payload.categoryScoreType,
                                    testedId: result.practice_tested_id,
                                    questions: [],
                                    isShowFeedback: result.is_immediately_get_answer,
                                };

                                // 設置選項
                                let setOptions = options => {
                                    let tempOptions = [];
                                    for (let option of options) {
                                        // 選項圖片
                                        let tempOptionImage = {};
                                        if (option.title.file_info.images[0]) {
                                            tempOptionImage.url = option.title.file_info.images[0].file_url;
                                        }

                                        tempOptions.push({
                                            id: option.title.qo_id,
                                            text: option.title.text,
                                            image: tempOptionImage,
                                            isDisableSelected: false  // 是否選項可被選擇(重組題適用)
                                        });
                                    }

                                    return tempOptions;
                                }

                                // 練習題目
                                for (let el of result.questions) {
                                    // 題目/題幹音檔
                                    let tempTitleAudio = {};
                                    if (el.title.file_info.audios[0]) {
                                        tempTitleAudio.url = el.title.file_info.audios[0].file_url;
                                        tempTitleAudio.isPlayingSound = false;  // 是否正在播放音檔
                                    }
                                    // 題目/題幹圖片
                                    let tempTitleImage = {};
                                    if (el.title.file_info.images[0]) {
                                        tempTitleImage.url = el.title.file_info.images[0].file_url;
                                        tempTitleImage.isLoadedError = false;  // 圖片是否載入失敗
                                    }

                                    // 題組子題
                                    let tempChildQuestions = [];
                                    for (let question of el.sub_questions) {
                                        // 子題圖片
                                        let tempChildQuesImage = {};
                                        if (question.title.file_info.images[0]) {
                                            tempChildQuesImage.url = question.title.file_info.images[0].file_url;
                                            tempChildQuesImage.isLoadedError = false;  // 圖片是否載入失敗
                                        }

                                        tempChildQuestions.push({
                                            id: question.id,
                                            answerType: question.answer_type,
                                            score: question.score,
                                            title: {
                                                text: question.title.text,
                                                image: tempChildQuesImage
                                            },
                                            options: setOptions(question.options)  // 選項
                                        });
                                    }

                                    state.practiceQuestionInfo.questions.push({
                                        id: el.id,
                                        // 0: 單選  1: 多選  2: 填充  3: 重組  4: 口說
                                        answerType: el.answer_type,
                                        score: el.score,
                                        title: {
                                            audio: tempTitleAudio,
                                            text: el.title.text,
                                            image: tempTitleImage
                                        },
                                        childQuestions: tempChildQuestions,  // 題組子題
                                        options: setOptions(el.options)  // 選項(非題組)
                                    });
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
    }
}

export default moduleArticle
