/**
 * 取得適用對象文字
 * @param {String} val 適用對象
 * @returns {String} 適用對象文字
 */
const getApplicability = (val) => {
    switch (val) {
        case '0': {
            return '國小';
        }
        case '1': {
            return '國中';
        }
        case '2': {
            return '高中';
        }
    }
};

/**
 * 取得等級程度文字
 * @param {String} val 等級程度
 * @returns {String} 等級程度文字
 */
const getDifficultyLevel = (val) => {
    switch (val) {
        case '0': {
            return '初級';
        }
        case '1': {
            return '中級';
        }
        case '2': {
            return '中高級';
        }
        case '3': {
            return '高級';
        }
        case '4': {
            return '優級';
        }
    }
};

/**
 * 取得更新頻率文字
 * @param {String} val 更新頻率
 * @returns {String} 更新頻率文字
 */
const getUpdateFrequency = (val) => {
    switch (val) {
        case '1': {
            return '每日';
        }
        case '2': {
            return '每周';
        }
        case '3': {
            return '每月';
        }
        default: {
            return '';
        }
    }
};

/**
 * 取得課程狀態文字
 * @param {String} val 課程狀態
 * @returns {String} 課程狀態文字
 */
const setStateText = (val) => {
    switch (val) {
        case '0': {
            return '編輯中';
        }
        case '1': {
            return '待審核';
        }
        case '2': {
            return '已排程';
        }
        case '3': {
            return '上架中';
        }
        case '4': {
            return '停售中';
        }
        case '5': {
            return '已封存';
        }
    }
};

const utils = {
    getApplicability,
    getDifficultyLevel,
    getUpdateFrequency,
    setStateText,
};

export default utils;
