const moduleAdminSchoolBuying = {
    namespaced: true,
    state: {
        // common
        isClickSidebarLink: false,  // 是否點擊 sidebar link
        countyDistrict: [], // 縣市區域
    },
    mutations: {
        setIsClickSidebarLink(state, payload) {
            state.isClickSidebarLink = payload;
        },
        setCountyDistrict(state, payload) {
            state.countyDistrict = payload;
        }
    },
    actions: {
    }
}

export default moduleAdminSchoolBuying