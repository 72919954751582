export default {
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'gray',
            validator: value => {
                return ['gray', 'white', 'orange'].includes(value)
            }
        },
        weight: {
            type: String,
            default: '',
            validator: value => {
                return ['', 'light'].includes(value)
            }
        }
    },
    computed: {
        spinnerStyle() {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`
            }
        }
    }
}
