import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// modules (順序依照檔案名稱第一個字 a-z)
import moduleArticle from './modules/article'
import moduleCommon from './modules/common'
import moduleCourse from './modules/course'
import moduleOrder from './modules/order'
import moduleUser from './modules/user'
import moduleAdminArticle from './modules/admin/article'
import moduleAdminCourse from './modules/admin/course'
import moduleAdminBuyingBundles from './modules/admin/buying_bundles'
import moduleAdminOrder from './modules/admin/order'
import moduleAdminSchoolBuying from './modules/admin/school_buying'
import moduleAdminGroupBuying from './modules/admin/group_buying'
import moduleAdminUser from './modules/admin/user'
import moduleAdminPnAnnouncement from './modules/admin/push_notification/announcement'
import moduleAdminPnBanner from './modules/admin/push_notification/banner'

export default new Vuex.Store({
    modules: {
        article: moduleArticle,
        common: moduleCommon,
        course: moduleCourse,
        order: moduleOrder,
        user: moduleUser,
        // admin
        adminArticle: moduleAdminArticle,
        adminCourse: moduleAdminCourse,
        adminBuyingBundles: moduleAdminBuyingBundles,
        adminOrder: moduleAdminOrder,
        adminSchoolBuying: moduleAdminSchoolBuying,
        adminGroupBuying: moduleAdminGroupBuying,
        adminUser: moduleAdminUser,
        adminPnAnnouncement: moduleAdminPnAnnouncement,
        adminPnBanner: moduleAdminPnBanner
    }
})
