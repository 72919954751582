const moduleAdminPnAnnouncement = {
    namespaced: true,
    state: {
        // 列表相關變數
        newPublishedItem: {},  // 新的上架公告
        newScheduledItem: {},  // 新的排程公告
        newUnpublishedItem: {},  // 新的下架公告
        editedScheduledItem: {},  // 已編輯完成的排程公告
        deletedPublishedId: null,  // 欲刪除的上架公告 id
        deletedScheduledId: null,  // 欲刪除的排程公告 id
        deletedUnpublishedId: null,  // 欲刪除的下架公告 id
        isDeleteAllAnnouncements: false,  // 是否刪除所有下架公告

        // 新增或編輯公告相關變數
        editingAnnouncementId: null,  // 正在新增/編輯的公告 id
        linkedResourceInfo: {},  // 連結項目資訊
    },
    mutations: {
        // 列表相關
        setNewPublishedItem(state, payload) {
            state.newPublishedItem = payload;
        },
        setNewScheduledItem(state, payload) {
            state.newScheduledItem = payload;
        },
        setNewUnpublishedItem(state, payload) {
            state.newUnpublishedItem = payload;
        },
        setEditedScheduledItem(state, payload) {
            state.editedScheduledItem = payload;
        },
        setDeletedPublishedId(state, payload) {
            state.deletedPublishedId = payload;
        },
        setDeletedScheduledId(state, payload) {
            state.deletedScheduledId = payload;
        },
        setDeletedUnpublishedId(state, payload) {
            state.deletedUnpublishedId = payload;
        },
        setIsDeleteAllAnnouncements(state, payload) {
            state.isDeleteAllAnnouncements = payload;
        },

        // 新增或編輯公告相關
        setEditingAnnouncementId(state, payload) {
            state.editingAnnouncementId = payload;
        },
        setLinkedResourceInfo(state, payload) {
            state.linkedResourceInfo = payload;
        },
    },
    actions: {
    }
}

export default moduleAdminPnAnnouncement
