import $_ from 'lodash'
import util from '@/global/util'

/**
 * 回傳優惠券折扣類型各數值對應的文字
 * @param     string  val
 *
 * @return    string  對應的優惠券類型名稱
 */
function setDiscountTypeText(val) {
    switch (val) {
        case '0':
            return '體驗券';
        case '1':
            return '折價券';
        case '2':
            return '折扣券';
    }
}

/**
 * 回傳優惠券有效期限格式化後的字串
 * @param     string|number  unixTimestamp  時間戳
 *
 * @return    string  對應格式化後的字串
 */
function formatEndDatetime(unixTimestamp) {
    let datetime = util.unixTimestampToDatetime(unixTimestamp, util.getBrowserCurrentTimeZone(), 'datetime', true);

    return datetime;
}

/**
 * 回傳處理後的訂單商品資訊
 * @param     object   courseInfo     API取得的課程資訊
 * @param     object   subscribePlan  訂閱方案資訊
 *
 * @return    object  對應處理後的訂單商品資訊
 */
function setOrderItemInfo(courseInfo, subscribePlan = {}, quantity = 1) {
    let result = {};

    result = {
        course: {
            id: courseInfo.id,
            alias: courseInfo.alias,
            name: courseInfo.name,
            cover: courseInfo.cover,
            purchaseType: courseInfo.purchaseType,
            suitable: courseInfo.suitable ? courseInfo.suitable : '0',
            difficultyLevel: courseInfo.difficultyLevel ? courseInfo.difficultyLevel : '0'
        },
        order: {
            id: '0',
            type: 'price',
            quantity: quantity, // 課程的數量
            unit: 1,
            price: courseInfo.price ? +courseInfo.price : 0,
            originalPrice: courseInfo.price ? +courseInfo.price : 0,
            isFitBundleCase: false,  // 是否符合合購優惠
            bundleCaseId: '0'  // 合購課程組合 id
        }
    }

    // 訂閱課程方案
    if (!$_.isEmpty(subscribePlan)) {
        result.order.id = subscribePlan.id;
        result.order.type = subscribePlan.type;
        result.order.unit = +subscribePlan.unit;
        result.order.price = +subscribePlan.price;
        result.order.planName = subscribePlan.planName;  // 訂閱方案名稱
    }

    return result;
}

/**
 * 複製課程兌換代碼
 *
 * @return    object  toast 狀態與訊息
 */
function copyExchangeCode(index = '') {
    let copyText = document.getElementById("exchangeCode"+index);
    let responseToast = {};

    copyText.disabled = false;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    let isSuccess = document.execCommand("copy");

    if (isSuccess) {
        copyText.disabled = true;
        window.getSelection().removeAllRanges();  // remove all selection ranges
        responseToast = { status: 'success', msg: '複製代碼成功' };
    } else {
        responseToast = { status: 'danger', msg: '複製代碼失敗' };
    }

    return responseToast;
}


const methods = {
    setDiscountTypeText,
    formatEndDatetime,
    setOrderItemInfo,
    copyExchangeCode
}

export default methods
