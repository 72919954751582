import axios from 'axios'
import store from '@/store'

let axiosInstance = axios.create();  // 建立實體
axiosInstance.defaults.baseURL = process.env.VUE_APP_ROOT_API;
axiosInstance.defaults.withCredentials = true;

/**
 * Error Code
 * 0: 本地端網路錯誤
 * 1: 本地端例外錯誤
 * 2: 伺服器端回應錯誤
 */
let errMsg = {
    '0': '網路異常，請確認網路連線狀況',
    '1': '系統繁忙中，請稍後再試',
    '2': '系統繁忙中，請稍後再試'
}
let errLog = {
    '0': `Client network error`,
    '1': `Client exception error`,
    '2': `Server exception error`
}

let serverResponseTimeout = 1500;

let isShowErrorToast = true;

/**
 * 送出 GET 請求
 * @param {String} url 請求網址
 * @param {Object} params 請求參數 (預設 {})
 * @param {Boolean} errorToast 是否顯示錯誤 toast (預設 true)
 * @returns {Object|String} 請求的回應結果
 */
function get(url, params = {}, errorToast = true) {
    return new Promise((resolve, reject) => {
        isShowErrorToast = errorToast;

        axiosInstance.get(url, { params: params })
            .then(response => {
                if (_handleResponse(response)) {
                    resolve(response);
                } else {
                    setTimeout(() => {
                        reject(_getRejectedMsg('2', url));
                    }, serverResponseTimeout);
                }
            })
            .catch(error => {
                let errCode = error ? '0' : '1';
                reject(_getRejectedMsg(errCode, url));
            });
    });
}

/**
 * 送出 POST 請求
 * @param {String} url 請求網址
 * @param {Object} params 請求參數 (預設 {})
 * @param {Boolean} errorToast 是否顯示錯誤 toast (預設 true)
 * @returns {Object|String} 請求的回應結果
 */
function post(url, params = {}, errorToast = true) {
    return new Promise((resolve, reject) => {
        isShowErrorToast = errorToast;

        axiosInstance.post(url, params)
            .then(response => {
                if (_handleResponse(response)) {
                    resolve(response);
                } else {
                    setTimeout(() => {
                        reject(_getRejectedMsg('2', url));
                    }, serverResponseTimeout);
                }
            })
            .catch(error => {
                let errCode = error ? '0' : '1';
                reject(_getRejectedMsg(errCode, url));
            });
    });
}

function _handleResponse(response) {
    // 後端邏輯錯誤
    if (!response.data.state) {
        return false;
    }

    // 後端回應 state = ERROR
    if (response.data.state == 'ERROR') {
        if (response.data.msg == 'NOT LOGIN') {
            // 使用者被伺服器端登出
            store.dispatch('common/logout');
        } else if (isShowErrorToast) {
            store.dispatch('common/setAlert', { msg: response.data.msg, status: 'danger' });
        }
    }

    return true;
}

function _getRejectedMsg(errCode, url) {
    let msg = `Http Request Error: ${errLog[errCode]} (URL: ${url})`;
    console.error(msg);

    if (errMsg[errCode]) {
        store.dispatch('common/setAlert', { msg: errMsg[errCode], status: 'danger', duration: -1 });
    }

    return msg;
}


const methods = {
    get,
    post
}

export default methods
