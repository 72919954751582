import Vue from 'vue'
import $_ from 'lodash'
import httpRequest from '@/global/http.js'
// utils
import orderUtil from '@/assets/js/utils/order'

const moduleOrder = {
    namespaced: true,
    state: {
        orderUtil: orderUtil,  // 訂單常用函數

        // 贈送訂單相關變數
        isGiftOrder: false,  // 是否為贈送訂單
        isCheckToRenewOrder: true,  // 訂閱制課程是否自動續訂

        // 訂單商品相關變數
        currentOrderItem: {},  // 目前訂單商品 (訂閱課程)
        currentGiftOrderItems: [],  // 目前贈送訂單的商品列表 (贈送課程)
        tempOrderItemsInfo: {},  // 暫存的訂單商品資訊
        orderItemsInfo: {},  // 自用訂單商品資訊
        giftOrderItemsInfo: {},  // 贈送訂單商品資訊
        orderItemCourseIds: [],  // 購買商品列表的課程 ids
        giftExchangeCount: 1, // 贈送數量，避免換頁數字被變更

        // 優惠券選取相關變數
        selectedCoupon: {},  // 使用者選擇的優惠券
        confirmSelectedCoupon: {},  // 使用者確定使用的優惠券

        // 結帳與金流支付相關變數
        currentSubTotal: 0,  // 目前訂單小計
        currentTotalAmount: 0,  // 目前訂單結帳總金額(折扣後)
        paymentPageHtml: '',  // 金流支付頁面 HTML

        // 付款結果相關變數
        finishedOrderItemList: [],  // 從API取得的已完成訂單商品列表
    },
    getters: {
        currentCoursePurchaseType: (state) => {  // 目前訂單課程的購買制度(0: 訂閱制  1: 購買制)
            if (state.orderItemCourseIds.length) {
                let info = state.isGiftOrder ? state.giftOrderItemsInfo : state.orderItemsInfo;
                return info[state.orderItemCourseIds[0]].course.purchaseType;
            }
        },
    },
    mutations: {
        // 贈送訂單相關
        setIsGiftOrder(state, payload) {
            state.isGiftOrder = payload;
        },
        setIsCheckToRenewOrder(state, payload) {
            state.isCheckToRenewOrder = payload;
        },

        // 訂單商品相關
        setCurrentOrderItem(state, payload) {
            state.currentOrderItem = payload;
        },
        setCurrentGiftOrderItems(state, payload) {
            state.currentGiftOrderItems = payload;
        },
        setOrderItemsInfo(state, payload) {
            state.orderItemsInfo = payload;
        },
        setGiftOrderItemsInfo(state, payload) {
            state.giftOrderItemsInfo = payload;
        },
        setOrderItemCourseIds(state, payload) {
            state.orderItemCourseIds = payload;
        },
        setGiftExchangeCount(state, payload) {
            state.giftExchangeCount = payload;
        },

        // 優惠券選取相關
        setSelectedCoupon(state, payload) {
            state.selectedCoupon = payload;
        },
        setConfirmSelectedCoupon(state, payload) {
            state.confirmSelectedCoupon = payload;
        },

        // 結帳與金流支付相關
        setCurrentSubTotal(state, payload) {
            state.currentSubTotal = payload;
        },
        setCurrentTotalAmount(state, payload) {
            state.currentTotalAmount = payload;
        },
        setPaymentPageHtml(state, payload) {
            state.paymentPageHtml = payload;
        },

        // 付款結果相關
        setFinishedOrderItemList(state, payload) {
            state.finishedOrderItemList = payload;
        },
    },
    actions: {
        // 訂閱制 - 新增商品
        addOrdersItem({ state, commit }, payload) {
            if ($_.isEmpty(payload.courseInfo) || $_.isEmpty(payload.selectedPrice)) {
                return;
            }

            return new Promise((resolve, reject) => {
                commit('setCurrentOrderItem', payload);

                if (!$_.isEmpty(state.currentOrderItem)) {
                    resolve();
                } else {
                    reject("store/modules/order.js: 'addOrdersItem' error");
                }
            });
        },

        // 課程制 - 購物車新增商品
        getCartItemCourseIds({ state, rootState }) {
            return new Promise((resolve, reject) => {
                httpRequest.get('/api/shopping_cart/get_cart_info')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let itemCourseIds = [];
                                state.tempOrderItemsInfo = {};

                                for (let el of result) {
                                    itemCourseIds.push(el.id);

                                    let tempCourseInfo = {
                                        id: el.id,
                                        alias: el.alias,
                                        name: el.name,
                                        cover: !$_.isEmpty(el.cover) ? el.cover.cover_url : '',
                                        purchaseType: '1',
                                        suitable: el.suitable,
                                        difficultyLevel: el.difficulty_level,
                                        price: el.price
                                    }
                                    state.tempOrderItemsInfo[el.id] = orderUtil.setOrderItemInfo(tempCourseInfo);
                                }

                                // 若原本顯示的購物車數量與新列表總數不一致時, 需重新取得 header '購物車' 資訊
                                let oldCount = rootState.common.cartItemsCount;
                                if (itemCourseIds.length !== oldCount) {
                                    rootState.common.isSetHeaderDataReady.getMyCartData = false;
                                }
                                // 修改 header 購物車上方的數字
                                rootState.common.cartItemsCount = itemCourseIds.length;

                                resolve(itemCourseIds);
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getCourseBundleCases({ state }, payload) {
            // courseIds 為空時則不發 API
            if (!payload.courseIds || !Array.isArray(payload.courseIds) || !payload.courseIds.length) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    courseIds: payload.courseIds
                }

                httpRequest.post('/api/course_bundle/get_course_bundle', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                // 贈送訂單
                                if (payload.isGiftOrder) {
                                    state.tempOrderItemsInfo = state.giftOrderItemsInfo;
                                }

                                let responseBundleCases = {};

                                for (let caseId in result.bundle_case) {
                                    let tempCase = result.bundle_case[caseId];
                                    let bundleCourses = tempCase.bundle_courses;

                                    // 檢查目前訂單的課程是否包含此合購優惠的所有課程
                                    let intCourses = $_.intersection(payload.courseIds, Object.keys(bundleCourses));
                                    let isExistBundleCase = intCourses.length === Object.keys(bundleCourses).length;

                                    // 若訂單商品符合合購優惠, 則需顯示合購折扣後的價錢
                                    if (isExistBundleCase) {
                                        for (let courseId in bundleCourses) {
                                            if (!state.tempOrderItemsInfo[courseId]) {
                                                continue;
                                            }

                                            let tempCourse = bundleCourses[courseId];

                                            // 價錢旁邊顯示 '合購優惠'
                                            state.tempOrderItemsInfo[courseId].order.isFitBundleCase = true;
                                            // 目前顯示的價錢改為合購優惠價錢
                                            state.tempOrderItemsInfo[courseId].order.price = +tempCourse.discount_price;
                                        }
                                    }

                                    // 若訂單內有符合合購優惠的其中一堂課程, 則需提示與其他課程合購享有優惠
                                    // 若自己已購買其中一堂課程, 不需提示
                                    let isSkipCase = !payload.isGiftOrder && tempCase.exist_course;
                                    if (!isSkipCase) {
                                        let courses = [], courseNames = [];
                                        for (let courseId in bundleCourses) {
                                            let tempCourse = bundleCourses[courseId];

                                            courses.push({
                                                id: courseId,
                                                name: tempCourse.name,
                                                cover: tempCourse.cover.cover_url,
                                                purchaseType: '1',
                                                suitable: tempCourse.suitable,
                                                difficultyLevel: tempCourse.difficulty_level,
                                                price: +tempCourse.price,
                                                discountPrice: +tempCourse.discount_price
                                            });

                                            courseNames.push(`「${tempCourse.name}」`);  // 課程名稱前後需加上「」
                                        }

                                        responseBundleCases[caseId] = {
                                            courses: courses,
                                            courseNames: courseNames,
                                            discount: +tempCase.bundle_discount,
                                            isPromptToUser: !isExistBundleCase  // 是否提示合購組合給使用者
                                        }
                                    }
                                }

                                // 記錄各課程對應到的合購課程組合 id
                                for (let courseId in result.course_correspond) {
                                    if (!state.tempOrderItemsInfo[courseId]) {
                                        continue;
                                    }

                                    let tempCaseId = result.course_correspond[courseId];
                                    if (responseBundleCases[tempCaseId]) {
                                        state.tempOrderItemsInfo[courseId].order.bundleCaseId = tempCaseId;
                                    }
                                }

                                // merge tempOrderItemsInfo to orderItemsInfo/giftOrderItemsInfo
                                if (payload.isGiftOrder) {
                                    state.giftOrderItemsInfo = Object.assign(state.giftOrderItemsInfo, state.tempOrderItemsInfo);
                                } else {
                                    state.orderItemsInfo = Object.assign(state.orderItemsInfo, state.tempOrderItemsInfo);
                                }

                                state.tempOrderItemsInfo = {};

                                resolve(responseBundleCases);
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        addItemsToCart({ state, rootState }, payload) {
            if (!payload.courseIds || !Array.isArray(payload.courseIds)) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    courseIds: payload.courseIds
                }

                httpRequest.post('/api/shopping_cart/add_cart', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let isExistBundleCourses = {};

                                for (let id of payload.courseIds) {
                                    let tempCourseInfo = {
                                        id: result[id].id,
                                        alias: result[id].alias,
                                        name: result[id].name,
                                        cover: result[id].cover.cover_url,
                                        purchaseType: '1',
                                        suitable: result[id].suitable,
                                        difficultyLevel: result[id].difficulty_level,
                                        price: result[id].price
                                    }
                                    let tempItem = orderUtil.setOrderItemInfo(tempCourseInfo);
                                    Vue.set(state.orderItemsInfo, id, tempItem);

                                    // 新增的課程, 隸屬的合購方案中, 其他課程是否也已都加入購物車
                                    isExistBundleCourses[id] = result[id].exist_bundle;
                                }

                                // 新增商品至購物車需重新取得 header '購物車' 資訊
                                rootState.common.isSetHeaderDataReady.getMyCartData = false;
                                // header 購物車上方的數字需增加
                                rootState.common.cartItemsCount += payload.courseIds.length;

                                resolve(isExistBundleCourses);
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        deleteItemFromCart({ rootState }, payload) {
            if (!payload.courseId) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    courseId: payload.courseId
                }

                httpRequest.post('/api/shopping_cart/delete_cart', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            // 從購物車刪除商品需重新取得 header '購物車' 資訊
                            rootState.common.isSetHeaderDataReady.getMyCartData = false;
                            // header 購物車上方的數字需減少
                            rootState.common.cartItemsCount--;

                            resolve();
                        }
                    })
                    .catch(error => reject(error));
            });
        },

        // 贈送課程
        addGiftOrderItems({ state, commit }, payload) {
            if (!payload.orderItems || !Array.isArray(payload.orderItems) || !payload.orderItems.length) {
                return;
            }

            return new Promise((resolve, reject) => {
                commit('setCurrentGiftOrderItems', payload.orderItems);

                if (state.currentGiftOrderItems.length) {
                    resolve();
                } else {
                    reject("store/modules/order.js: 'addGiftOrderItems' error");
                }
            });
        }
    }
}

export default moduleOrder
