/**
 * 取得元件距離頁面頂部的偏移量
 * @param {HTMLElement} elem HTML元件
 * @returns {Number} 偏移量
 */
function getElementTop(elem) {
    if (!elem) {
        return;
    }

    // `offsetTop`為當前元件相對於其`offsetParent`元件的頂部內邊距
    let actualTop = elem.offsetTop;
    let current = elem.offsetParent;

    while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
    }

    return actualTop;
}

/**
 * 防抖動
 * @param {Function} fn 函式
 * @param {Number} delay 延遲毫秒數
 * @returns {Function} 延遲執行函式內容
 */
function debounce(fn, delay = 1000) {
    let timerId = null;
    return (...args) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => fn(...args), delay);
    };
}

/**
 * 依據內容多寡調整 <textarea> 高度
 * 若無帶入元件, 則該頁面所有 <textarea> 元件皆會被調整
 * @param {HTMLElement} elem HTML元件
 * @param {Boolean} limitedHeight <textarea>是否有最大高度限制
 */
function resizeTextarea(elem, limitedHeight = false) {
    let elems = [];

    if (!elem) {
        elems = document.getElementsByTagName('textarea');
    } else {
        elems = [elem];
    }

    const key = limitedHeight ? 'height' : 'minHeight';
    for (const elem of elems) {
        elem.style[key] = '';
        elem.style[key] = `${elem.scrollHeight}px`;
    }
}


const methods = {
    getElementTop,
    debounce,
    resizeTextarea,
}

export default methods
