import $_ from 'lodash'
import httpRequest from '@/global/http.js'
// utils
import courseUtil from '@/assets/js/utils/course'

const moduleCourse = {
    namespaced: true,
    state: {
        courseUtil: courseUtil,  // 課程常用函數

        // 課程資料相關變數
        courseBasicInfo: {},  // 課程基本資訊

        // 課程價錢選擇相關變數
        selectedPrice: {},  // 訂閱制課程選擇的售價資訊
    },
    mutations: {
        // 課程資料相關
        setCourseBasicInfo(state, payload) {
            state.courseBasicInfo = payload;
        },

        // 課程價錢選擇相關
        setSelectedPrice(state, payload) {
            state.selectedPrice = payload;
        },
    },
    actions: {
        getCourseBasicInfo({ commit }, payload) {
            if (!payload.courseId) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    courseId: payload.courseId
                }

                httpRequest.get('/api/course/get_basic_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result && !$_.isEmpty(result)) {
                                // 課程基本資訊
                                let courseInfo = result.course_info;
                                let courseInfoData = {
                                    id: courseInfo.id,
                                    alias: courseInfo.alias,
                                    state: courseInfo.state,
                                    isPreOrder: courseInfo.is_pre_order,
                                    groupId: courseInfo.course_group_id,
                                    name: courseInfo.name,
                                    purchaseType: courseInfo.purchase_type,
                                    price: courseInfo.price,
                                    metaTitle: courseInfo.meta_title,
                                }

                                commit('setCourseBasicInfo', {
                                    courseInfo: courseInfoData,
                                    isCourseUser: result.is_course_user
                                });

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
    }
}

export default moduleCourse
