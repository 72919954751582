import $_ from 'lodash'
import i18n from '@/global/vue_i18n'
import httpRequest from '@/global/http'
import cookieStore from '@/global/cookie'

const moduleCommon = {
    namespaced: true,
    state: {
        // lang
        lang: localStorage.getItem('language') || 'zh-TW',
        // window size / pointer
        windowWidth: window.innerWidth,
        isPointerFine: matchMedia('(pointer:fine)').matches,  // 是否有設備可以精準點擊(e.g. 滑鼠)
        // user
        userInfo: {},
        isSetUserInfoReady: false,
        // page
        layout: 'div',
        isAdminPage: false,
        // header
        isShowHeader: true,
        headerActiveLink: '',
        headerChildrenActiveLink: '',
        isSetHeaderDataReady: {
            getMyRecentSearchedData: false,  // 取得 '最近搜尋紀錄' 資訊
            getMyCartData: false  // 取得 '購物車' 資訊
        },
        cartItemsCount: 0,
        inputKeyword: '',  // 搜尋框輸入的關鍵字內容
        isShowSearchBar: false,  // for "優化前過渡版本 (Mobile)"
        searchTotal: 0,
        // footer
        isShowFooter: true,
        // alert
        isShowAlert: false,
        alert: {
            msg: '',
            status: '',
            duration: 3000
        },
        alertTimeProcedure: '',
        // media_previewer
        isShowMediaPreviewer: false,
        // app webview
        isAndroidWebview: navigator.userAgent.includes('lms_plus_Android_App'),
        isiOSWebview: navigator.userAgent.includes('lms_plus_iOS_App'),
        // app download properties
        appDownload: {
            QRCodeImage: require('@/assets/image/APP_download/QRCode.png'),
            iOS: {
                link: 'https://apps.apple.com/us/app/加分吧/id1572597817',
                image: require('@/assets/image/APP_download/iOS.png')
            },
            Android: {
                link: 'https://play.google.com/store/apps/details?id=net.learnmode.plus',
                image: require('@/assets/image/APP_download/Android.png')
            }
        },
        // county and district
        countyDistrict: {}
    },
    mutations: {
        // lang
        changeLocale(state, payload) {
            state.lang = payload;
            i18n.locale = state.lang;
            localStorage.setItem('language', state.lang);
        },
        // user
        setUserInfo(state, payload) {
            state.userInfo = payload;
        },
        changeUserInfo(state, payload) {
            let keys = Object.keys(payload);
            keys.map(x => state.userInfo[x] = payload[x]);
        },
        setIsSetUserInfoReady(state, payload) {
            state.isSetUserInfoReady = payload;
        },
        // page
        setLayout(state, payload) {
            state.layout = payload;
        },
        setIsAdminPage(state, payload) {
            state.isAdminPage = payload;
        },
        // header
        setIsShowHeader(state, payload) {
            state.isShowHeader = payload;
        },
        setHeaderActiveLink(state, payload) {
            state.headerActiveLink = payload;
        },
        setHeaderChildrenActiveLink(state, payload) {
            state.headerChildrenActiveLink = payload;
        },
        setIsSetHeaderDataReady(state, payload) {
            state.isSetHeaderDataReady = payload;
        },
        setCartItemsCount(state, payload) {
            state.cartItemsCount = payload;
        },
        setInputKeyword(state, payload) {
            state.inputKeyword = payload;
        },
        setIsShowSearchBar(state, payload) {
            state.isShowSearchBar = payload;
        },
        setSearchResultTotal(state, payload) {
            state.searchTotal = payload;
        },
        // footer
        setIsShowFooter(state, payload) {
            state.isShowFooter = payload;
        },
        // alert
        changeAlert(state, payload) {
            if (state.alertTimeProcedure) {
                clearTimeout(state.alertTimeProcedure);
            }
            state.alert = {
                msg: payload.msg,
                status: payload.status,
                duration: payload.duration == undefined ? 3000 : payload.duration
            };
            state.isShowAlert = true;
            if (state.alert.duration > 0) {
                state.alertTimeProcedure = setTimeout(() => this.commit('common/resetAlert'), state.alert.duration);
            }
        },
        resetAlert(state) {
            state.isShowAlert = false;
            clearTimeout(state.alertTimeProcedure);
        },
        // media_previewer
        setIsShowMediaPreviewer(state, payload) {
            state.isShowMediaPreviewer = payload;
        },
        // county and district
        setCountyDistrict(state, payload) {
            state.countyDistrict = payload;
        }
    },
    actions: {
        async setLanguage({ commit }, payload) {  // lazy load
            try {
                const res = await import(`@/i18n/${payload}.json`);
                i18n.setLocaleMessage(payload, res);
                commit('changeLocale', payload);
            }
            catch (error) {
                console.log(error);
            }
        },
        getUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                httpRequest.get('/api/user/profile')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let defaultAvatar = require('@/assets/image/default_avatar/avatar_sm.png'),
                                    data = {
                                        userId: result.user_id,
                                        name: result.name,
                                        avatar: result.file_name,
                                        permission: result.permission,
                                        email: result.contact_email,
                                        county: result.county,
                                        grade: result.grade,
                                        adminPermission : result.admin_permission,
                                        isNotAllowOrder: result.is_not_allow_order,
                                        isAllowCountyReport: result.is_allow_county_report,
                                    }
                                if (!data.avatar) {
                                    data.avatar = defaultAvatar;
                                }
                                commit('setUserInfo', data);
                                resolve();
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            resolve();
                        }
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        updateUserInfo({ commit }, payload) {
            commit('changeUserInfo', payload);
        },
        logout({ state, commit }) {
            const cookieKeys = ['PHPSESSID', 'access_token', 'refresh_token'];
            cookieKeys.forEach(key => {
                cookieStore.delCookie(key, `.${location.hostname}`);
            });
            commit('setUserInfo', {});
            state.isSetHeaderDataReady = $_.mapValues(state.isSetHeaderDataReady, () => false);
        },
        setAlert({ commit }, payload) {
            if (payload.msg == undefined) {
                return;
            }
            commit('changeAlert', payload);
        },
        getCountyDistrict({ state, commit }) {
            if (!$_.isEmpty(state.countyDistrict)) {
                return;
            }

            return new Promise((resolve) => {
                $.getJSON('https://resource.learnmode.net/static/county_district.json', function(data) {
                    commit('setCountyDistrict', data);
                    resolve();
                });
            });
        }
    }
}

export default moduleCommon
