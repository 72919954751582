// device
const isMac = /Macintosh/i.test(navigator.userAgent) && !navigator.maxTouchPoints;
const isIpad = /Macintosh|iPad/i.test(navigator.userAgent) && navigator.maxTouchPoints > 0;
const isIphone = /iPhone/i.test(navigator.userAgent);

// browser
const isChrome = /Chrome|CriOS/i.test(navigator.userAgent);
const isSafari = /Safari/i.test(navigator.userAgent);


const consts = {
    isMac,
    isIpad,
    isIphone,
    isChrome,
    isSafari
}

export default consts
