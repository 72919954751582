import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

/**
 * meta 說明
 * - requiresAuth: 該 route 是否要求使用者登入
 */

const routes = [
    // 首頁
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home.vue'),
        meta: { requiresAuth: false, pageTitle: process.env.VUE_APP_HOME_TITLE, isDefault: true }
    },
    // 產品介紹
    {
        path: '/introduction',
        name: 'Introduction',
        component: () => import('@/views/introduction.vue'),
        meta: { requiresAuth: false, pageTitle: '產品介紹', isDefault: true }
    },
    // 搜尋
    {
        path: '/search',
        name: 'Search',
        component: () => import('@/views/search.vue'),
        meta: { requiresAuth: true, pageTitle: '文章搜尋', isDefault: true }
    },
    // 課程
    {
        path: '/course/:id',
        name: 'Course',
        component: () => import('@/views/course.vue'),
        meta: { requiresAuth: false, pageTitle: '課程首頁', isDefault: false },
        children: [
            {  // 進入課程
                path: ':page(home)',
                name: 'CourseChildren1',
                component: () => import('@/views/course.vue'),
                meta: { requiresAuth: false, pageTitle: '課程首頁', isDefault: false },
                children: [  // 課程分類
                    {
                        path: ':categoryId',
                        name: 'CourseChildren1-1',
                        component: () => import('@/views/course.vue'),
                        meta: { requiresAuth: true, pageTitle: '課程分類', isDefault: false },
                    }
                ]
            }
        ]
    },
    // 文章 | 文章預覽
    {
        path: '/:articleIndex(article|article-preview)/:id(\\d+)',
        name: 'Article',
        component: () => import('@/views/article.vue'),
        meta: { requiresAuth: false, pageTitle: '文章預覽', isDefault: false },
        children: [
            {
                path: ':page(read|listen|teach|practice|favorites)',
                name: 'ArticleChildren1',
                component: () => import('@/views/article.vue'),
                meta: { requiresAuth: true, pageTitle: '文章預覽', isDefault: false },
            },
            {  // 題目練習 - 練習紀錄(最後10次作答歷程)
                path: ':page(practice-history)/:categoryId(\\d+)',
                name: 'ArticleChildren2',
                component: () => import('@/views/article.vue'),
                meta: { requiresAuth: true, pageTitle: '題目練習 - 練習紀錄', isDefault: false },
            },
            {  // 題目練習 - 練習進行中
                path: ':page(practicing)',
                name: 'ArticleChildren3',
                component: () => import('@/views/article.vue'),
                meta: { requiresAuth: true, pageTitle: '題目練習 - 練習進行中', isDefault: false },
                children: [  // v2
                    {
                        path: ':version(v2)',
                        name: 'ArticleChildren3-1',
                        component: () => import('@/views/article.vue')
                    }
                ]
            },
        ]
    },
    // 教學(學習)中心
    {
        path: '/learning-center',
        name: 'LearningCenter',
        component: () => import('@/views/learning_center.vue'),
        meta: { requiresAuth: true, pageTitle: '學習中心', isDefault: true },
        children: [
            {
                // course | homework | event | favorite | note | vocabulary | group | portfolio
                path: ':page(course)',
                name: 'LearningCenterChildren1',
                component: () => import('@/views/learning_center.vue'),
                meta: { requiresAuth: true, pageTitle: '學習中心', isDefault: false },
            },
        ],
    },
    // 我的專區
    {
        path: '/user',
        name: 'User',
        component: () => import('@/views/user.vue'),
        meta: { requiresAuth: true, pageTitle: '個人資訊', isDefault: true },
        children: [
            {
                path: ':page(coupons|gifts|favorite|mission|portfolio)',
                name: 'UserChildren1',
                component: () => import('@/views/user.vue'),
                meta: { requiresAuth: true, pageTitle: '個人資訊', isDefault: false },
            },
            {  // 訂購紀錄
                path: ':page(orders|gift-orders)',
                name: 'UserChildren2',
                component: () => import('@/views/user.vue'),
                meta: { requiresAuth: true, pageTitle: '訂購紀錄', isDefault: true },
                children: [
                    {  // 訂閱制、課程制
                        path: ':subPage(subscribe|purchased)',
                        name: 'UserChildren2-1',
                        component: () => import('@/views/user.vue'),
                        meta: { requiresAuth: true, pageTitle: '訂購紀錄', isDefault: true },
                    }
                ]
            },
            {  // 群組(班級)管理
                path: ':page(group)',
                name: 'UserChildren3',
                component: () => import('@/views/user.vue'),
                meta: { requiresAuth: true, pageTitle: '班級管理', isDefault: true },
                children: [
                    {
                        path: ':groupId(\\d+)',
                        name: 'UserChildren3-1',
                        component: () => import('@/views/user.vue'),
                        meta: { requiresAuth: true, pageTitle: '班級管理', isDefault: true },
                    }
                ]
            }
        ]
    },
    // 訂單
    //  '- 訂單內容 (訂閱制課程, 一次只能買一堂課)
    //  '- 購物車 (課程制課程, 一次可買多堂課)
    //  '- 贈送內容 (訂閱/課程制課程, 一次買一堂課, 合購課程可一次購買一組)
    {
        path: '/:orderIndex(orders|cart|gift-orders)',
        name: 'Order',
        component: () => import('@/views/order.vue'),
        meta: { requiresAuth: true, pageTitle: '訂購內容', isDefault: true }
    },
    // 訂單 - 填寫付款資訊
    {
        path: '/checkout',
        name: 'OrderCheckout',
        component: () => import('@/views/order.vue'),
        meta: { requiresAuth: true, pageTitle: '付款資訊', isDefault: true },
        children: [
            {  // 金流支付
                path: ':page(payment)',
                name: 'OrderCheckoutChildren1',
                component: () => import('@/views/order.vue'),
                meta: { requiresAuth: true, pageTitle: '付款資訊', isDefault: true },
            },
            {  // 付款狀態
                path: ':orderNo(\\d+)/:state(success|fail)',
                name: 'OrderCheckoutChildren2',
                component: () => import('@/views/order.vue'),
                meta: { requiresAuth: true, pageTitle: '付款資訊', isDefault: true },
            }
        ]
    },
    // 活動
    {
        path: '/event/:alias',
        name: 'Event',
        component: () => import('@/views/event.vue'),
        meta: { requiresAuth: false, pageTitle: '活動資訊', isDefault: true },
        children: [
            {  // Q & A、團隊清單(團體)、報名資訊(個人)
                path: ':page(help|my-register|team-register|personal-register)',
                name: 'EventChildren1',
                component: () => import('@/views/event.vue'),
                meta: { requiresAuth: false, pageTitle: '活動資訊', isDefault: true },
            },
            {  // 排行榜
                path: ':page(charts)',
                name: 'EventChildren2',
                component: () => import('@/views/event.vue'),
                meta: { requiresAuth: false, pageTitle: '活動排行', isDefault: true },
                children: [
                    {  // 團體、個人
                        path: ':chartsType(team|individual)',
                        name: 'EventChildren2-1',
                        component: () => import('@/views/event.vue'),
                        meta: { requiresAuth: false, pageTitle: '活動排行', isDefault: true },
                    }
                ]
            }
        ]
    },
    // 報表下載頁面
    {
        path: '/report',
        name: 'ReportDownload',
        component: () => import('@/views/report.vue'),
        meta: { requiresAuth: true, pageTitle: '報表下載', isDefault: true },
    },
    // footer - 問題回報
    {
        path: '/feedback',
        name: 'Feedback',
        component: () => import('@/views/feedback.vue'),
        meta: { requiresAuth: true, pageTitle: '問題回報', isDefault: true },
        children: [
            {
                path: ':page(success)',
                name: 'FeedbackChildren1',
                component: () => import('@/views/feedback.vue'),
                meta: { requiresAuth: true, pageTitle: '問題回報', isDefault: true },
            },
        ],
    },
    // footer - 隱私權政策
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import('@/views/privacy.vue'),
        meta: { requiresAuth: false, pageTitle: '隱私權政策', isDefault: true }
    },
    // footer - 服務條款
    {
        path: '/terms',
        name: 'Terms',
        component: () => import('@/views/terms.vue'),
        meta: { requiresAuth: false, pageTitle: '服務條款', isDefault: true }
    },
    // footer - 購買暨服務契約
    {
        path: '/agreement',
        name: 'Agreement',
        component: () => import('@/views/agreement.vue'),
        meta: { requiresAuth: false, pageTitle: '購買暨服務契約', isDefault: true }
    },
    // 系統後台
    {
        path: '/admin/:page/:type/:subPage',
        name: 'Admin',
        component: () => import('@/views/admin.vue'),
        meta: { requiresAuth: true, pageTitle: '系統後台', isDefault: true },
        children: [
            {
                path: ':id(\\d+)',
                name: 'AdminChildren1',
                component: () => import('@/views/admin.vue')
            }
        ]
    },
    // OAuth
    {
        path: '/oauth/:state(auth|login|error|verifyaccount)',
        name: 'OAuth',
        component: () => import('@/views/oauth.vue'),
        meta: { requiresAuth: false, pageTitle: process.env.VUE_APP_TITLE, isDefault: true }
    },
    // 以上皆無法匹配, 則導向 NotFound 頁面
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/not_found.vue'),
        meta: { requiresAuth: false, pageTitle: process.env.VUE_APP_TITLE, isDefault: true }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => {  // 轉換頁面時, 捲軸拉至最上面
        return { x: 0, y: 0 };
    },
    routes
})

export default router
