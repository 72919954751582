import httpRequest from '@/global/http.js'
import util from '@/global/util.js'
import $_ from 'lodash'

const moduleAdminArticle = {
    namespaced: true,
    state: {
        // common
        isClickSidebarLink: false,  // 是否點擊 sidebar link
        // _edit page
        articleBasicInfo: null,  // 文章基本資訊
        oldArticleName: '',  // 舊的文章名稱
        oldArticleType: '',
        updateArticleData: null,  // 要更新的文章資料
        isShowNotSaveArticlePrompt: false,  // 更換頁面時, 是否顯示系統不自動儲存提示
        isFinishUpdatingData: false,  // 是否已經完成更新文章資料
        isInputFormatError: false,  // 是否有輸入框的內容格式錯誤
        isSelfDeletedFromEditors: false,  // 是否從文章編輯者中刪除自己
        newEditorList: [],  // 新編輯者列表
        articleLinkCourseId: 0, // 新增文章需要連結某個自己的課程
        selectedSubjectIndex: '', // 文章練習題目前被選擇的index，用於預覽顯示判斷
    },
    mutations: {
        setIsClickSidebarLink(state, payload) {
            state.isClickSidebarLink = payload;
        },
        setArticleBasicInfo(state, payload) {
            state.articleBasicInfo = payload;
        },
        setOldArticleName(state, payload) {
            state.oldArticleName = payload;
        },
        setOldArticleType(state, payload) {
            state.oldArticleType = payload;
        },
        changeArticleType(state, payload) { // 文章[儲存]後須更新articleBasicInfo內容
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'type')) {
                state.articleBasicInfo.type = payload;
            }
        },
        changeArticleName(state, payload) { // 文章[儲存]後須更新articleBasicInfo內容
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'name')) {
                state.articleBasicInfo.name = payload;
            }
        },
        changeArticleSubName(state, payload) { // 文章[儲存]後須更新articleBasicInfo內容
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'subName')) {
                state.articleBasicInfo.subName = payload;
            }
        },
        changeArticleComment(state, payload) { // 文章[儲存]後須更新articleBasicInfo內容
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'comment')) {
                state.articleBasicInfo.comment = payload;
            }
        },
        changeArticleStartTime(state, payload) { // 文章[儲存]後須更新articleBasicInfo內容
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'commentTimePointStart')) {
                state.articleBasicInfo.commentTimePointStart = payload;
            }
        },
        changeArticleEndTime(state, payload) { // 文章[儲存]後須更新articleBasicInfo內容
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'commentTimePointEnd')) {
                state.articleBasicInfo.commentTimePointEnd = payload;
            }
        },
        changeArticleIsRandomQuestion(state, payload) { // 文章[儲存]後須更新articleBasicInfo內容
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'isRandomQuestion')) {
                state.articleBasicInfo.isRandomQuestion = payload;
            }
        },
        changeArticleIsRandomQuestionOption(state, payload) { // 文章[儲存]後須更新articleBasicInfo內容
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'isRandomQuestionOption')) {
                state.articleBasicInfo.isRandomQuestionOption = payload;
            }
        },
        changeArticleIsShowScore(state, payload) { // 文章[儲存]後須更新articleBasicInfo內容
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'isShowScore')) {
                state.articleBasicInfo.isShowScore = payload;
            }
        },
        changeArticleIsInstantResponseAnswer(state, payload) { // 文章[儲存]後須更新articleBasicInfo內容
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'isInstantResponseAnswer')) {
                state.articleBasicInfo.isInstantResponseAnswer = payload;
            }
        },
        cancelSavingArticle(state) {
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'name')) {
                state.articleBasicInfo.name = state.oldArticleName;
            }
            if (typeof state.articleBasicInfo == 'object' && $_.has(state.articleBasicInfo, 'type')) {
                state.articleBasicInfo.type = state.oldArticleType;
            }
            state.updateArticleData = null;
        },
        setUpdateArticleData(state, payload) {
            state.updateArticleData = payload;
        },
        setIsShowNotSaveArticlePrompt(state, payload) {
            state.isShowNotSaveArticlePrompt = payload;
        },
        setIsFinishUpdatingData(state, payload) {
            state.isFinishUpdatingData = payload;
        },
        setIsInputFormatError(state, payload) {
            state.isInputFormatError = payload;
        },
        setIsSelfDeletedFromEditors(state, payload) {
            state.isSelfDeletedFromEditors = payload;
        },
        addNewEditorToList(state, payload) {
            state.newEditorList.push(payload);
        },
        setNewEditorList(state, payload) {
            state.newEditorList = payload;
        },
        addArticleLinkCourseId(state, payload) {
            state.articleLinkCourseId = payload;
        },
        setArticleLinkCourseId(state, payload) {
            state.articleLinkCourseId = payload;
        },
        setSubjectIndex(state, payload) {
            state.selectedSubjectIndex = payload;
        },
    },
    actions: {
        getArticleBasicInfo({ commit }, payload) {
            if (!payload.articleId) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    articleId: payload.articleId
                }

                httpRequest.get('/admin_api/article/get_article_basic_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;
                            if (result) {
                                let data = {
                                    type: result.type,
                                    courseId: result.course_id,
                                    courseName: result.course_name,
                                    name: result.name,
                                    subName: result.sub_name,
                                    comment: result.comment,
                                    commentTimePointStart: result.comment_time_point_start,
                                    commentTimePointEnd: result.comment_time_point_end,
                                    state: result.state,
                                    isRandomQuestion: result.is_random_question,
                                    isRandomQuestionOption: result.is_random_question_option,
                                    isShowScore: result.is_show_score,
                                    isInstantResponseAnswer: result.is_instant_response_answer,
                                    publishTime: null
                                }
                                // 已排程, 需有排程上架時間
                                if (data.state == '2') {
                                    data.publishTime = util.unixTimestampToDatetime(result.publish_date, util.getBrowserCurrentTimeZone());
                                }

                                commit('setArticleBasicInfo', data);

                                // 暫存原本的文章名稱
                                // 若編輯者沒有儲存新的文章名稱時, 需將 title bar 內的文章名稱替換回原本的
                                commit('setOldArticleName', data.name);
                                commit('setOldArticleType', data.type);

                                resolve();
                            } else {
                                reject("store/modules/admin/article.js: 'getArticleBasicInfo' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("store/modules/admin/article.js: 'getArticleBasicInfo' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("store/modules/admin/article.js: 'getArticleBasicInfo' get error");
                    });
            });
        },
    }
}

export default moduleAdminArticle
