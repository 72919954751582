import $_ from 'lodash'

/**
 * 回傳等級程度各數值對應的文字
 * @param     string  val
 *
 * @return    string  對應的等級程度
 */
function setDifficultyLevelText(val) {
    switch (val) {
        case '0':
            return '初級';
        case '1':
            return '中級';
        case '2':
            return '中高級';
        case '3':
            return '高級';
        case '4':
            return '優級';
    }
}

/**
 * 回傳文章狀態各數值對應的文字
 * @param     string  val
 *
 * @return    string  對應的文章狀態
 */
 function setStateText(val) {
    switch (val) {
        case '0':
            return '編輯中';
        case '1':
            return '待審核';
        case '2':
            return '已排程';
        case '3':
            return '上架中';
        case '4':
            return '已封存';
    }
}

/**
 * 回傳英文詞性對應的縮寫
 * @param     string  category
 *
 * @return    string  對應的縮寫
 */
function setLexicalCategoryAbbrev(category) {
    switch (category) {
        case 'Noun':  // 名詞
            return 'n.';
        case 'Countable noun':  // 可數名詞
            return 'c.';
        case 'Uncountable noun':  // 不可數名詞
            return 'u.';
        case 'Plural':  // 複數詞
            return 'pl.';
        case 'Pronoun':  // 代名詞
            return 'pron.';
        case 'Subject':  // 主詞
            return 's.';
        case 'Verb':  // 動詞
            return 'v.';
        case 'Intransitive Verb':  // 不及物動詞
            return 'Vi.';
        case 'Transitive Verb':  // 及物動詞
            return 'Vt.';
        case 'Auxiliary Verbs':  // 助動詞
            return 'aux.';
        case 'Object':  // 受詞
            return 'o.';
        case 'Direct object':  // 間接受詞
            return 'D.O.';
        case 'Indirect object':  // 間接受詞
            return 'I.O.';
        case 'Adjective':  // 形容詞
            return 'adj.';
        case 'Adverb':  // 副詞
            return 'adv.';
        case 'Complement':  // 補語
            return 'comp.';
        case 'Subjective complement':  // 主詞補語
            return 'S.C.';
        case 'Objective complement':  // 受詞補語
            return 'O.C.';
        case 'Preposition':  // 介系詞
            return 'prep.';
        case 'Article':  // 冠詞
            return 'art.';
        case 'Conjunction':  // 連結詞
            return 'conj.';
        case 'Interjection':  // 感嘆詞
            return 'int.';
        case 'Determiner':  // 限定詞
            return 'det.';
        case 'Combining Form':  // 構詞成分
            return 'comb.';
        case 'Idiomatic':  // 慣用語
            return 'idiom.';
        case 'Particle':  // 虛詞
            return 'ptcl.';
        case 'Other':  // 其他
            return 'other.';
        default:
            return category;
    }
}

/**
 * 輸入可能為英文單字 + 標點符號, 回傳可被字典查詢的英文單字
 * @param     string   word           英文單字
 * @param     boolean  isToLowerCase  輸出結果是否將單字內容全部轉為小寫
 *
 * @return    string  處理過的英文單字
 */
function handleWord(word, isToLowerCase) {
    let words = word.match(/[\w-'|’]+|[^\w\s]+/g);

    if (!words) {
        return;
    }

    let formattedWords = [];
    words.forEach(el => {
        if (/\w/.test(el)) {
            formattedWords.push(el);
        }
    });

    let result = '';
    if (!formattedWords.length) {
        return;
    }
    if (formattedWords.length > 1) {
        result = word;
    } else if (formattedWords.length == 1) {
        result = formattedWords[0];
    }

    return isToLowerCase ? result.toLowerCase() : result;
}

/**
 * 設定收藏單字按鈕的 DOM 屬性值
 * @param     string  property  屬性
 * @param     object  isMarked  是否被收藏
 *
 * @return    string  屬性值
 */
function setMarkBtnProperty(property, isMarked) {
    switch (property) {
        case 'hoverText':
            return isMarked ? '取消收藏' : '收藏單字';
        case 'icon':
            return isMarked ?
                require('@/assets/image/icon/48x48/bookmark_orange_active.png') :
                require('@/assets/image/icon/48x48/bookmark_default.png');
    }
}

/**
 * 底部工具列(bottom-toolbar)在卷軸滾動時的事件處理函式
 * 設定底部工具列 position, 預設為 sticky, 判斷卷軸滾動至 footer 時, 即改為 absolute
 */
function bottomToolbarScrollHandler() {
    let elemFooterHeight = $('.lms-footer').height();  // footer 高度

    let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;  // 取得 elem 因包含 overflow 而沒顯示在螢幕上的內容高度
    let windowHeight = $(window).innerHeight();  // 取得目前可視範圍高度
    let scrollTop = $(window).scrollTop(); // 取得卷軸目前的 Y座標 位置

    if (windowHeight + scrollTop >= scrollHeight - elemFooterHeight) {
        $('.bottom-toolbar').addClass('absoulte-position').removeClass('sticky-position');
    } else {
        $('.bottom-toolbar').addClass('sticky-position').removeClass('absoulte-position');
    }
}

/**
 * 回傳題目類型各數值對應的文字 [待移除]
 * @param     string  val
 *
 * @return    string  對應的題目類型
 */
function setPracticeQuestionTypeText(val) {
    switch (val) {
        case '0':
            return '單選';
        case '1':
            return '多選';
        case '2':
            return '填充';
        case '3':
            return '重組';
        case '4':
            return '朗讀';
    }
}

/**
 * 回傳題目的組成類型
 * @param     object  question  題目資訊
 *
 * @return    number  對應的組成類型編號
 */
function setQuestionContentType(question) {
    // # Example 'question' Format
    // question = {
    //     audio: {
    //         url: '',
    //         isPlayingSound: false
    //     },
    //     text: '',
    //     image: {
    //         url: '',
    //         isLoadedError: false
    //     }
    // }

    // # Type Value
    // 0: none
    // 1: ['audio']
    // 2: ['text']
    // 3: ['image']
    // 4: ['audio', 'text']
    // 5: ['audio', 'image']
    // 6: ['text', 'image']
    // 7: ['audio', 'text', 'image']

    let type;

    if ($_.isEmpty(question.audio) && !question.text && !question.image.url) {
        type = 0;
    }
    if ((!$_.isEmpty(question.audio) && question.audio.url) &&
        !question.text &&
        !question.image.url) {
        type = 1;
    }
    if ($_.isEmpty(question.audio) && question.text && !question.image.url) {
        type = 2;
    }
    if ($_.isEmpty(question.audio) && !question.text && question.image.url) {
        type = 3;
    }
    if ((!$_.isEmpty(question.audio) && question.audio.url) &&
        question.text &&
        !question.image.url) {
        type = 4;
    }
    if ((!$_.isEmpty(question.audio) && question.audio.url) &&
        !question.text &&
        question.image.url) {
        type = 5;
    }
    if ($_.isEmpty(question.audio) && question.text &&  question.image.url) {
        type = 6;
    }
    if ((!$_.isEmpty(question.audio) && question.audio.url) &&
        question.text &&
        question.image.url) {
        type = 7;
    }

    return type;
}

/**
 * 回傳題目各選項的組成資訊
 * @param     array  options  選項資訊陣列
 *
 * @return    object  各選項組成的資訊物件(含是否需雙欄排版 & 各選項對應的組成類型編號)
 */
function setChoiceOptionContentTypeInfo(options) {
    // # Example 'options' Format
    // options = [
    //     {
    //         id: 1,
    //         text: '',
    //         image: {
    //             url: ''
    //         }
    //     },
    //     {
    //         id: 2,
    //         text: '',
    //         image: {
    //             url: ''
    //         }
    //     }
    // ]

    // # Type Value
    // 0: none
    // 1: ['text']
    // 2: ['image']
    // 3: ['text', 'image']

    // # Result Format
    // {
    //    isDoubleCols: true | false,
    //    option: {
    //        '1': 0 | 1 | 2 | 3,
    //        '2': 0 | 1 | 2 | 3,
    //        ...
    //    }
    // }

    // 先掃過所有選項, 若碰到含圖片內容的選項時, 則一律使用雙欄 2列顯示
    let isDoubleCols = false;  // 是否雙欄顯示
    options.some(el => {
        if (el.image.url) {
            isDoubleCols = true;
            return true;
        } else {
            return false;
        }
    });

    let info = {
        isDoubleCols: isDoubleCols,
        option: {}
    }

    // 再掃過所有選項, 取得各選項內容的組成類型
    options.forEach(el => {
        let tempType;

        if (!el.text && !el.image.url) {
            tempType = 0;
        }
        if (el.text && !el.image.url) {
            tempType = 1;
        }
        if (!el.text && el.image.url) {
            tempType = 2;
        }
        if (el.text && el.image.url) {
            tempType = 3;
        }

        info.option[el.id] = tempType;
    });

    return info;
}

/**
 * 依據題目各選項的作答狀態, 回傳對應的樣式名稱
 * @param     object  optionStatus     選項作答狀態
 * @param     array   handInOptionIds  使用者有作答的選項 id 陣列
 *
 * @return    object  各選項對應的樣式名稱 (key name 為 選項 id)
 */
function setHandInOptionStatusStyle(optionStatus, handInOptionIds) {
    if ($_.isEmpty(optionStatus) || !handInOptionIds.length) {
        return {};
    }

    // # Example 'optionStatus' Format
    // optionStatus = {  // key name 為 選項 id
    //     '1': false,
    //     '2': true,
    //     '3': true,
    //     '4': false
    // }

    // 不管使用者有沒有選擇, optionStatus true -> 'is-correct'
    // 使用者有選擇, optionStatus false -> 'is-wrong'
    // 使用者沒有選擇, optionStatus false -> ''

    let style = {};

    for (let optionId in optionStatus){
        style[optionId] = '';

        if (optionStatus[optionId]) {
            style[optionId] = 'is-option-marked is-correct';
        } else {
            if (handInOptionIds.includes(optionId)) {
                style[optionId] = 'is-option-marked is-wrong';
            }
        }
    }

    return style;
}


const methods = {
    setDifficultyLevelText,
    setStateText,
    setLexicalCategoryAbbrev,
    handleWord,
    setMarkBtnProperty,
    bottomToolbarScrollHandler,
    setPracticeQuestionTypeText,
    setQuestionContentType,
    setChoiceOptionContentTypeInfo,
    setHandInOptionStatusStyle,
}

export default methods
