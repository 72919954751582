const moduleAdminPnBanner = {
    namespaced: true,
    state: {
        // 列表相關變數
        newPublishedItem: {},  // 新的上架橫幅
        newScheduledItem: {},  // 新的排程橫幅
        newUnpublishedItem: {},  // 新的下架橫幅
        editedPublishedItem: {},  // 已編輯完成的上架橫幅
        editedScheduledItem: {},  // 已編輯完成的排程橫幅
        deletedPublishedId: null,  // 欲刪除的上架橫幅 id
        deletedScheduledId: null,  // 欲刪除的排程橫幅 id
        deletedUnpublishedId: null,  // 欲刪除的下架橫幅 id
        isDeleteAllBanners: false,  // 是否刪除所有下架橫幅

        // 新增或編輯橫幅相關變數
        editingBannerInfo: { index: null, id: null, state: null },  // 正在新增/編輯的橫幅資訊
        linkedResourceInfo: {},  // 連結項目資訊
    },
    mutations: {
        // 列表相關
        setNewPublishedItem(state, payload) {
            state.newPublishedItem = payload;
        },
        setNewScheduledItem(state, payload) {
            state.newScheduledItem = payload;
        },
        setNewUnpublishedItem(state, payload) {
            state.newUnpublishedItem = payload;
        },
        setEditedPublishedItem(state, payload) {
            state.editedPublishedItem = payload;
        },
        setEditedScheduledItem(state, payload) {
            state.editedScheduledItem = payload;
        },
        setDeletedPublishedId(state, payload) {
            state.deletedPublishedId = payload;
        },
        setDeletedScheduledId(state, payload) {
            state.deletedScheduledId = payload;
        },
        setDeletedUnpublishedId(state, payload) {
            state.deletedUnpublishedId = payload;
        },
        setIsDeleteAllBanners(state, payload) {
            state.isDeleteAllBanners = payload;
        },

        // 新增或編輯橫幅相關
        setEditingBannerInfo(state, payload) {
            state.editingBannerInfo = payload;
        },
        setLinkedResourceInfo(state, payload) {
            state.linkedResourceInfo = payload;
        },
    },
    actions: {
    }
}

export default moduleAdminPnBanner
