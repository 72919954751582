import $_ from 'lodash'
import httpRequest from '@/global/http.js'

const moduleUser = {
    namespaced: true,
    state: {
        // 使用者資訊相關變數
        userDetailInfo: {},  // API取得的使用者資訊
        oldUserDetailInfo: {},  // 暫存的使用者資訊, 以用來比對判斷是否發API更新資訊

        // 我的優惠券相關變數
        couponsCanUseInfo: {},  // 優惠券是否可以在購買使用的資訊

        // 訂購紀錄相關變數
        isGiftOrder: false,  // 是否顯示贈送訂單紀錄
        activeUnsubscribeOrder: {},  // 正在 '取消訂閱' 的訂單

        // 成員管理相關變數
        isEditGroupName: false, // 是否處於班級編輯名稱狀態
        isDeleteGroup: false, // 是否處於班級刪除狀態
        isGroupSave: false, // 是否處於班級儲存狀態
        isGroupAssign: false, // 是否處於成員指派狀態
        updateGroupData: null,  // 要更新的群組列表資料
        isUserEdit: false, // 是否處於成員編輯狀態
        isUserSave: false, // 是否處於成員儲存狀態
        isUserDelete: false, // 是否處於成員刪除狀態
        isUserAssign: false, // 是否處於成員指派狀態
        updateUserData: null,  // 要更新的成員列表資料
        updateCourseData: null, // 儲存課程指派ID
        oidcStudentIds: [], // 暫存OIDC學生ID

        // 書籤相關變數
        isShowDeleteArticles: false, // 是否處於文章刪除狀態
        isDisableOtherBtn: false, // 是否disable其它按鈕
    },
    mutations: {
        // 使用者資訊相關
        setUserDetailInfo(state, payload) {
            state.userDetailInfo = payload;
        },
        setOldUserDetailInfo(state, payload) {
            state.oldUserDetailInfo = payload;
        },

        // 我的優惠券相關
        setCouponsCanUseInfo(state, payload) {
            state.couponsCanUseInfo = payload;
        },

        // 訂購紀錄相關
        setIsGiftOrder(state, payload) {
            state.isGiftOrder = payload;
        },
        setActiveUnsubscribeOrder(state, payload) {
            state.activeUnsubscribeOrder = payload;
        },

        // 成員管理相關
        setIsEditGroupName(state, payload) {
            state.isEditGroupName = payload;
        },
        setIsDeleteGroup(state, payload) {
            state.isDeleteGroup = payload;
        },
        setIsGroupSave(state, payload) {
            state.isGroupSave = payload;
        },
        setIsGroupAssign(state, payload) {
            state.isGroupAssign = payload;
        },
        setUpdateGroupListData(state, payload) {
            state.updateGroupData = payload;
        },
        setIsUserEdit(state, payload) {
            state.isUserEdit = payload;
        },
        setIsUserDelete(state, payload) {
            state.isUserDelete = payload;
        },
        setIsUserSave(state, payload) {
            state.isUserSave = payload;
        },
        setIsUserAssign(state, payload) {
            state.isUserAssign = payload;
        },
        setUpdateUserListData(state, payload) {
            state.updateUserData = payload;
        },
        setUpdateCourseListData(state, payload) {
            state.updateCourseData = payload;
        },
        setUpdateStudentListData(state, payload) {
            state.updateStudentData = payload;
        },
        setOidcStudentIds(state, payload) {
            state.oidcStudentIds = payload;
        },
        setShowDeleteArticles(state, payload) {
            state.isShowDeleteArticles = payload;
        },
        setDisableOtherBtn(state, payload) {
            state.isDisableOtherBtn = payload;
        }
    },
    actions: {
        uploadUserDetailInfo({ state, dispatch, rootState }) {
            if ($_.isEmpty(rootState.common.userInfo)) {
                return;
            }

            let excludeKeys = ['avatar'];  // 排除個人頭像
            let userInfo = $_.omitBy($_.omit(state.userDetailInfo, excludeKeys), (v, k) => {
                return state.oldUserDetailInfo[k] == v;
            });

            // 如果沒有需更新的資料, 則不做以下處理
            if ($_.isEmpty(userInfo)) {
                return;
            }

            // 生日需轉換格式
            if ($_.has(userInfo, 'birth') && userInfo.birth) {
                userInfo.birth = userInfo.birth.replace(/\//g, '-');
            }

            // 國家與縣市需同時存在
            if ($_.has(userInfo, 'country') || $_.has(userInfo, 'counties')) {
                userInfo.country = state.userDetailInfo.country;
                userInfo.counties = state.userDetailInfo.counties;
            }

            let params = {
                userInfo: userInfo
            }

            httpRequest.post('/api/user/update_profile', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        // 若個人暱稱有更換, 則需更新 header
                        if ($_.has(userInfo, 'name')) {
                            dispatch('common/updateUserInfo', { name: userInfo.name }, { root: true });
                        }
                        // 若個人縣市有更換，則需要更新，活動報名時拿到的縣市才會是正確的
                        if ($_.has(userInfo, 'counties')) {
                            dispatch('common/updateUserInfo', { county: userInfo.counties }, { root: true });
                        }

                        dispatch('common/setAlert', { status: 'success', msg: response.data.msg }, { root: true });
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },

        getUserCouponIds({ state }, payload) {
            if (!payload.type || !payload.courseIds || !Array.isArray(payload.courseIds)) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    type: payload.type,
                    courseIds: payload.courseIds,
                    isGift: payload.isGift || false
                }

                httpRequest.post('/api/coupon/get_user_coupons_ids', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let userCouponIds = [];

                                for (let el of result) {
                                    userCouponIds.push(el.uc_id);
                                    state.couponsCanUseInfo[el.uc_id] = el.can_use;
                                }

                                resolve(userCouponIds);
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getCouponsInfo({ state }, payload) {
            if (!payload.userCouponIds || !Array.isArray(payload.userCouponIds)) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    userCouponIds: payload.userCouponIds
                }

                httpRequest.post('/api/coupon/get_user_coupons_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let couponList = [];

                                for (let id of payload.userCouponIds) {
                                    let tempCoupon = result[id];
                                    if (tempCoupon) {
                                        couponList.push({
                                            id: tempCoupon.uc_id,
                                            couponName: tempCoupon.coupon_name,
                                            courseName: tempCoupon.course_name,
                                            courseId: tempCoupon.course_id,
                                            courseAlias: tempCoupon.course_alias,
                                            couponType: tempCoupon.coupon_type,
                                            discountType: tempCoupon.discount_type,
                                            discount: tempCoupon.discount,
                                            endDate: tempCoupon.end_date,
                                            canUse: state.couponsCanUseInfo[id]
                                        });
                                    }
                                }

                                resolve(couponList);
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getNewCoupons({ state, dispatch }, payload) {
            if (!payload.code || !payload.courseIds || !Array.isArray(payload.courseIds)) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    code: payload.code,
                    courseIds: payload.courseIds,
                    isGift: payload.isGift || false
                }

                httpRequest.post('/api/coupon/user_get_coupon', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                dispatch('common/setAlert', { status: 'success', msg: response.data.msg }, { root: true });

                                let newCouponList = [];

                                for (let el of result) {
                                    let newUserCouponId = el.uc_id;

                                    newCouponList.push({
                                        id: newUserCouponId,
                                        isExist: el.is_exist
                                    });

                                    state.couponsCanUseInfo[newUserCouponId] = el.can_use;
                                }

                                resolve(newCouponList);
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject('Get Coupon Error');
                        }
                    })
                    .catch(error => reject(error));
            });
        },

        getExchangedCourseInfo(_, payload) {
            if (!payload.infos || !Array.isArray(payload.infos)) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    infos: payload.infos
                }

                httpRequest.post('/api/exchange/get_exchange_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let courseList = [];

                                for (let el of payload.infos) {
                                    let tempCourse = result[el.courseId];

                                    if (tempCourse) {
                                        courseList.push({
                                            id: el.courseId,
                                            alias: tempCourse.alias,
                                            orderNo: el.orderNo,
                                            exchangeDate: tempCourse.lastmod,
                                            name: tempCourse.name,
                                            state: tempCourse.state,
                                            isPreOrder: tempCourse.is_pre_order,
                                            cover: tempCourse.cover.cover_url,
                                            purchaseType: tempCourse.purchase_type,
                                            publishDate: tempCourse.publish_date,
                                            expiredDate: tempCourse.expired_date
                                        });
                                    }
                                }

                                resolve(courseList);
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getNewExchangedCourses({ dispatch }, payload) {
            if (!payload.code || !payload.allCourses || !Array.isArray(payload.allCourses)) {
                return;
            }

            return new Promise((resolve, reject) => {
                // 取得列表中所有課程 ids, 以便檢查新增的課程是否原本存在在列表中
                let allCourseIds = [];
                for (let el of payload.allCourses) {
                    allCourseIds.push(el.courseId);
                }

                let params = {
                    code: payload.code
                }

                httpRequest.get('/api/exchange/exchange_course_by_code', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                dispatch('common/setAlert', { status: 'success', msg: response.data.msg }, { root: true });

                                let newCourseList = [];

                                for (let id of result.course_ids) {
                                    newCourseList.push({
                                        courseId: id,
                                        orderNo: result.order_no,
                                        isExist: allCourseIds.includes(id)
                                    });
                                }

                                resolve(newCourseList);
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject('Exchange Course Error');
                        }
                    })
                    .catch(error => reject(error));
            });
        },
    }
}

export default moduleUser
