export default {
    watch: {
        '$route.path'(url) {
            const pageUrl = location.protocol + '//' + location.host + url;
            const canonical = document.querySelector('head link[rel="canonical"]');
            canonical.setAttribute('href', pageUrl);
            const ogUrl = document.querySelector('head meta[property="og:url"]');
            ogUrl.setAttribute('content', pageUrl);

            if (this.$route.meta.isDefault) {           // true: 以meta的資料為主
                this.$util.updateMeta(this.$route.meta.pageTitle);
            }
        }
    },
    computed: {
        layout: {
            get() {
                return this.$store.state.common.layout;
            },
            set(value) {
                this.$store.commit('common/setLayout', value);
            }
        }
    },
    beforeCreate: function() {
        this.$store.dispatch('common/setLanguage', this.$store.state.common.lang);
    },
    mounted: function() {
        window.addEventListener('resize', this.detectWindowWidth);

        const mediaQuery = '(pointer: fine)';
        const mediaQueryList = window.matchMedia(mediaQuery);
        mediaQueryList.addEventListener('change', event => this.$store.state.common.isPointerFine = event.matches);

        this.getViewHeight();
        window.addEventListener('resize', this.getViewHeight);

        // Bootstrap multiple modals
        $(document).on('show.bs.modal', '.modal', function() {
            const zIndex = 1050 + 10 * $('.modal:visible').length;
            $(this).css('z-index', zIndex);
            setTimeout(() => $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack'));
        });
        $(document).on('hidden.bs.modal', '.modal', function() {
            $('.modal:visible').length && $(document.body).addClass('modal-open');
        });
    },
    beforeDestroy: function() {
        window.removeEventListener('resize', this.detectWindowWidth);
        window.removeEventListener('resize', this.getViewHeight);
    },
    methods: {
        detectWindowWidth() {
            this.$store.state.common.windowWidth = window.innerWidth;
        },
        getViewHeight() {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    }
}
