import httpRequest from '@/global/http.js'
import util from '@/global/util.js'
import $_ from 'lodash'

const moduleAdminCourse = {
    namespaced: true,
    state: {
        // common
        isClickSidebarLink: false,  // 是否點擊 sidebar link
        // _edit page
        courseBasicInfo: null,  // 課程基本資訊
        oldCourseName: '',  // 舊的課程名稱
        updateCourseData: null,  // 要更新的課程資料
        isShowNotSaveCoursePrompt: false,  // 更換頁面時, 是否顯示系統不自動儲存提示
        isFinishUpdatingData: false,  // 是否已經完成更新課程資料
        isInputFormatError: false,  // 是否有輸入框的內容格式錯誤
        isSelfDeletedFromEditors: false,  // 是否從課程編輯者中刪除自己
        newEditorList: [],  // 新編輯者列表
    },
    mutations: {
        setIsClickSidebarLink(state, payload) {
            state.isClickSidebarLink = payload;
        },
        setCourseBasicInfo(state, payload) {
            state.courseBasicInfo = payload;
        },
        setOldCourseName(state, payload) {
            state.oldCourseName = payload;
        },
        changeCourseName(state, payload) {
            if (typeof state.courseBasicInfo == 'object' && $_.has(state.courseBasicInfo, 'name')) {
                state.courseBasicInfo.name = payload;
            }
        },
        changeCoursePurchaseType(state, payload) {
            if (typeof state.courseBasicInfo == 'object' && $_.has(state.courseBasicInfo, 'purchaseType')) {
                state.courseBasicInfo.purchaseType = payload;
            }
        },
        cancelSavingCourse(state) {
            if (typeof state.courseBasicInfo == 'object' && $_.has(state.courseBasicInfo, 'name')) {
                state.courseBasicInfo.name = state.oldCourseName;
            }
            state.updateCourseData = null;
        },
        setUpdateCourseData(state, payload) {
            state.updateCourseData = payload;
        },
        setIsShowNotSaveCoursePrompt(state, payload) {
            state.isShowNotSaveCoursePrompt = payload;
        },
        setIsFinishUpdatingData(state, payload) {
            state.isFinishUpdatingData = payload;
        },
        setIsInputFormatError(state, payload) {
            state.isInputFormatError = payload;
        },
        setIsSelfDeletedFromEditors(state, payload) {
            state.isSelfDeletedFromEditors = payload;
        },
        addNewEditorToList(state, payload) {
            state.newEditorList.push(payload);
        },
        setNewEditorList(state, payload) {
            state.newEditorList = payload;
        }
    },
    actions: {
        getCourseBasicInfo({ commit }, payload) {
            if (!payload.courseId) {
                return;
            }

            return new Promise((resolve, reject) => {
                let params = {
                    courseId: payload.courseId
                }

                httpRequest.get('/admin_api/course/get_course_basic_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let data = {
                                    name: result.name,
                                    state: result.state,
                                    purchaseType: result.purchase_type,
                                    publishTime: util.unixTimestampToDatetime(result.publish_date, util.getBrowserCurrentTimeZone()),
                                    stopSellingTime: util.unixTimestampToDatetime(result.unpublish_date, util.getBrowserCurrentTimeZone())
                                }

                                commit('setCourseBasicInfo', data);

                                // 暫存原本的課程名稱
                                // 若編輯者沒有儲存新的課程名稱時, 需將 title bar 內的課程名稱替換回原本的
                                commit('setOldCourseName', data.name);

                                resolve();
                            } else {
                                reject("store/modules/admin/course.js: 'getCourseBasicInfo' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("store/modules/admin/course.js: 'getCourseBasicInfo' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("store/modules/admin/course.js: 'getCourseBasicInfo' get error");
                    });
            });
        },
    }
}

export default moduleAdminCourse
