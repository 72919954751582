import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: store.state.common.lang,
    messages: {}
})

store.dispatch('common/setLanguage', i18n.locale);

export default i18n
