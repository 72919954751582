// main packages
import Vue from 'vue'
import App from './App.vue'
import i18n from './global/vue_i18n'
import store from './store'
import router from './router'
import firebase from './global/firebase'


// other packages
// bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/scss/vendors/bootstrap.scss'
// bootstrap-vue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import '@/assets/scss/vendors/bootstrap-vue.scss'
Vue.use(BootstrapVue, IconsPlugin)
// font awesome
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
library.add(fab, far, fas)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
Vue.component('font-awesome-icon', FontAwesomeIcon)
// vue-gtag
import VueGtag from 'vue-gtag'
Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GA_KEY },
    pageTrackerTemplate(to) {
        return {
            page_title: to.meta.pageTitle,
            page_path: to.path
        }
    }
}, router)
// lodash
import _ from 'lodash'
Vue.prototype.$_ = _


// global consts
// user agent
import userAgent from './global/user_agent'
Vue.prototype.$userAgent = userAgent


// global custom modules
// cookie
import cookieStore from './global/cookie'
Vue.prototype.$cookieStore = cookieStore
// http
import httpRequest from './global/http'
Vue.prototype.$httpRequest = httpRequest
// node
import node from './global/node'
Vue.prototype.$node = node
// utility functions
import util from './global/util'
Vue.prototype.$util = util


// global custom components
// loader
import PageLoader from './components/common/page_loader'
Vue.component('PageLoader', PageLoader)
import DotLoading from './components/common/loader/dot'
Vue.component('DotLoading', DotLoading)
import SpinnerLoading from './components/common/loader/spinner'
Vue.component('SpinnerLoading', SpinnerLoading)


// create vue instance
new Vue({
    i18n,
    store,
    router,
    firebase,
    render: h => h(App)
}).$mount('#app')
// config settings
Vue.config.productionTip = false  // 是否在console提示目前開發模式


// 各頁面切換前預處理, 適用一頁式跳轉(history mode)
router.beforeEach((to, from, next) => {
    // 判斷頁面是否需登入使用
    if (to.matched.some(record => {
        // console.log(record.name, record.meta.requiresAuth);
        return record.meta.requiresAuth;
    })) {
        if (_.isEmpty(store.state.common.userInfo)) {
            // 需登入的頁面需跳出註冊登入彈窗讓使用者登入
            $('#loginDialogue').modal('show');
        } else {
            $('.modal').modal('hide');
            next();
        }
    } else {
        $('.modal').modal('hide');
        next();
    }
});
