import { render, staticRenderFns } from "./spinner.vue?vue&type=template&id=ba93c478&scoped=true&"
import script from "@/assets/js/components/common/loader/spinner.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/common/loader/spinner.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/common/loader/spinner.scss?vue&type=style&index=0&id=ba93c478&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba93c478",
  null
  
)

export default component.exports